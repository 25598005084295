import classNames from 'classnames';
import { Block } from 'lib/metronic/components';
import React, {
  FC,
  HTMLProps,
  MouseEvent,
  PureComponent,
  ReactNode,
} from 'react';
import ReactDOM from 'react-dom';
import { Provider, Slot } from 'react-slot-fill';

interface Props {
  width?: string | number | (() => string | number);
  open?: boolean;
  spin?: boolean;
  showCloseButton?: boolean;
  className?: string;
  onMounted?: (el: HTMLElement | null | undefined) => void;
  onConfirmClose?: () => boolean | undefined;
  onClose?: () => void;
  autoClose?: boolean;
  preventEscClose?: boolean;
  floatedContents?: ReactNode | null;
  children?: ReactNode;
}

interface ContentProps extends HTMLProps<HTMLDivElement> {}

const AsideNav: FC<{ children?: ReactNode }> = React.memo(props => (
  <div className="kt-quick-panel__nav">{props.children}</div>
));

const AsideContent = React.memo<ContentProps>(
  ({ className, children, ...props }) => (
    <div
      className={classNames('kt-quick-panel__content', className)}
      {...props}
    >
      {children}
    </div>
  ),
);

export class AsideRight extends PureComponent<Props> {
  static defaultProps: Props = {
    open: false,
    spin: false,
    onClose: undefined,
    autoClose: true,
    floatedContents: null,
  };

  static Nav = AsideNav;
  static Content = AsideContent;

  private readonly elemRef = React.createRef<HTMLDivElement>();
  private readonly overlayRef = React.createRef<HTMLDivElement>();

  render() {
    return ReactDOM.createPortal(this.internalRender(), document.body);
  }

  componentDidMount() {
    this.props.onMounted && this.props.onMounted(this.elemRef.current);
    if (this.props.open) {
      this.elemRef.current &&
        this.elemRef.current.classList.add('kt-quick-panel--on');
      document.body.classList.add('kt-quick-panel--on');
      document.body.style.overflow = 'hidden';
      document.addEventListener('keyup', this.onKeyUp);
      setTimeout(() => {
        this.props.autoClose !== false &&
          this.overlayRef.current &&
          this.overlayRef.current.addEventListener('click', this.onClose);
      }, 0);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.onKeyUp);
    document.body.classList.remove('kt-quick-panel--on');
    document.body.style.overflow = 'auto';
    this.elemRef.current &&
      this.elemRef.current.classList.remove('kt-quick-panel--on');
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.open !== prevProps.open) {
      if (this.props.open) {
        setTimeout(() => {
          document.body.classList.add('kt-quick-panel--on');
          document.body.style.overflow = 'hidden';
          this.elemRef.current &&
            this.elemRef.current.classList.add('kt-quick-panel--on');
          document.addEventListener('keyup', this.onKeyUp);
          setTimeout(() => {
            this.props.autoClose !== false &&
              this.overlayRef.current &&
              this.overlayRef.current.addEventListener('click', this.onClose);
          }, 0);
        }, 0);
      } else {
        this.overlayRef.current &&
          this.overlayRef.current.removeEventListener('click', this.onClose);
        this.elemRef.current &&
          this.elemRef.current.classList.remove('kt-quick-panel--on');
        document.body.style.overflow = 'auto';
        document.body.classList.remove('kt-quick-panel--on');
        document.removeEventListener('keyup', this.onKeyUp);
      }
    }
  }

  onKeyUp = (e: KeyboardEvent) => {
    if (
      e.key === 'Escape' &&
      this.props.autoClose !== false &&
      this.props.preventEscClose !== true
    ) {
      this.onClose();
    }
  };

  internalRender() {
    const { open, spin, showCloseButton } = this.props;
    let width = this.props.width;
    if (typeof width === 'function') {
      width = width();
    }
    return (
      <Provider>
        <div
          ref={this.elemRef}
          className={classNames('kt-quick-panel', this.props.className)}
          style={{
            width,
            maxWidth: width && typeof width === 'number' ? width : undefined,
            minWidth: width && typeof width === 'number' ? width : undefined,
            right:
              width && typeof width === 'number' ? -(width + 20) : undefined,
          }}
        >
          <Block
            active={spin}
            zIndex={1005}
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
            }}
          >
            {showCloseButton && (
              <a
                href="#"
                className="kt-quick-panel__close"
                onClick={this.onCloseButtonClick}
              >
                <i className="flaticon2-delete" />
              </a>
            )}
            {this.props.children}
            <Slot name="aside-actions" />
          </Block>
          {this.props.floatedContents && (
            <div
              style={{
                position: 'absolute',
                left: -456,
                top: 100,
                bottom: 100,
                width: 455,
                overflowY: 'auto',
                backgroundColor: '#fff',
                boxShadow: '0px 1px 15px 1px rgba(69,65,78,0.08)',
              }}
            >
              {this.props.floatedContents}
            </div>
          )}
        </div>
        {open && (
          <div
            className="kt-quick-panel-overlay"
            style={{ backgroundColor: 'rgba(0,0,0,0.4)' }}
            ref={this.overlayRef}
          />
        )}
      </Provider>
    );
  }

  onClose = () => {
    if (!this.props.onConfirmClose || this.props.onConfirmClose() !== false) {
      this.props.onClose && this.props.onClose();
    }
  };

  onCloseButtonClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    this.onClose();
  };
}
